<template>
  <div class="business-page">
    <Header />
    <div class="breadcrumb">
      <div class="gradiant-bar"></div>
    </div>
    <MainId />

    <div class="ll-body container" role="main">

      <form v-on:submit.prevent>
        <section class="otp-section">

          <div class="form-title">Business Credit Application</div>

          <h1 class="section-title">Business Owner Financial Disclosures</h1>

          <div class="section-description">
            <p>Welcome to the FHB Loans and Lines Business Credit Application! </p>
            <p>We received your Business Credit application form. Please click the button below to complete and sign your Business Owner Financial Disclosures form.</p>
          </div>
          

          <!-- continue button -->
          <div>
            <button class="boe-button" id="personal-continue-btn" type="button" @click="handleContinue()"
              data-test="ll-bc-btn-continue">
              {{ this.isPersonalFi ? 'Complete Personal Financial Statement' : 'Complete Financial Disclosures' }}
            </button>
          </div>
        </section>

        <div class="v-spacer-30" />

      </form>
    </div>

  </div>
</template>

<script>
import Header from '@/components/Header'
import MainId from '@/components/MainId'
import jQuery from 'jquery'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Getting Started',
  components: {
    Header,
    MainId,
  },
  data() {

    return {
      isPersonalFi: false,
    }
  },
  created() {
    const id = this.$route.query.app_id;
    const oid = this.$route.query.owner_id;
    const isPersonalFi = this.$route.query.personal_fi && this.$route.query.personal_fi == 'true'
    if (isPersonalFi) {
      this.setOwnerIsPersonalFi(true)
      this.isPersonalFi = true
    }
    console.log("id is: ", id, "and owner id is: ", oid, "is personalFi: ", this.isPersonalFi)
    this.setOwnerId(oid)
    this.setAppId(id)

  },
  computed: {
    ...mapGetters("businessCreditOwner",
      ['applicationID', 'ownerId', 'appId' ]
    ),
    
  },
  mounted() {
    jQuery("html, body").animate({ scrollTop: 0 });

  },
  methods: {
    ...mapActions("businessCreditOwner",
      ['setOwnerId', 'setAppId', 'setOwnerIsPersonalFi']
    ),
    handleContinue() {
      this.$router.push({ name: 'Send Code' })
    
    },
    gotoFHB() {
      // form validation
      window.location.replace('https://fhb.com');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.ll-section {
  text-align: left;
}
.boe-button {
  width: auto;
  min-width: 15em;
  height: 40px;
  background-color: $red;
  color: $white;
  margin: 0px;
  border-radius: 20px;
  border-style: none;
  display: block;
  text-align: center;
  font-size: 0.95rem;
  padding: 0.25em 2.5em;
  position: relative;
  line-height: 1.5;
}

.boe-button:hover {
  cursor: pointer;
  background-color: $redHover;
}

.boe-button:focus {
  background-color: $redHover;
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }

  &+.ll-row {
    margin-top: 2em;
  }
}

.ll-item {
  position: relative;
}

.row1-container {
  @include media-breakpoint-up(sm) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 0.45em 1em 0.5em;
  width: flex; // ?

  span {
    display: block;
    flex: 1;
    font-weight: 700;
  }
}

.toggle-btn-container {
  align-items: stretch;
  column-gap: 1em;
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-bottom: 2px;
  row-gap: 1em;
}

.toggle-btn-container>* {
  column-gap: 0.25em;
  display: flex;

  @include media-breakpoint-down(md) {
    flex-basis: 100%;
  }
}

.toggle-btn-txt-bold {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-up,
.toggle-btn-down {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 25px;
}

.toggle-btn-up {
  background: $white;
  border: 1px solid $grayMid;
  box-shadow: 0px 3px 0px rgba(176, 176, 176, 0.5);

  &:hover,
  &:focus,
  &:active {
    background: rgba(254, 214, 123, 0.2);
    border: 1px solid $grayDark;
    box-shadow: 0px 3px 0px rgb(80 80 80 / 60%);
  }
}

.toggle-btn-down {
  background: rgba(254, 214, 123, 0.2);
  border: 1px solid $grayDark;
  box-shadow: inset 2px 4px 0px rgb(114 79 0 / 15%);
}

.toggle-btn-up:hover {
  cursor: pointer;
}

.toggle-btn-container .icon {
  flex-basis: 32px;
  text-align: center;
}

.toggle-btn-container .toggle-btn-txt {
  flex: 1;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-container-error {
  border: 1px dotted $red;
  padding: 10px;
}

.row2-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }

  >div+div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.row3-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
  }

  >div+div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.row4-container {
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>